import React from "react"
import { graphql } from "gatsby"

import ReactMarkdown from "react-markdown"
import Moment from "react-moment"

import { AuthProvider, AuthConsumer } from "../components/utils/AuthContext"
import ShareButtons from "../components/utils/ShareButtons"
import Seo from "../components/seo"
import Layout from "../components/layout"
import CategoryTag from "../components/categoryTag"
import Upvote from "../components/upvote"

export const query = graphql`
  query ArticleQuery($id: String!) {
    strapiArticle(strapiId: { eq: $id }) {
      strapiId
      title
      description
      content
      published_at
      url
      users {
        id
      }
      media
      category {
        name
      }
    }
  }
`

const Article = ({ data }) => {
  const article = data.strapiArticle

  const title = article.title
  const url = typeof window !== `undefined` ? window.location.href : ""
  const twitterHandle = "sabo_radu94"
  const summary = article.content
  const tags = [article.category.name]

  const formatVideo = () => {
    const ytRegex = /(?:youtu\.be\/|youtube(?:-nocookie)?\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})|youtube\.com\/playlist\?list=|youtube\.com\/user\//

    if (article.category.name === "Videos" && ytRegex.test(article.url)) {
      let dataUrlValid = article.url.match(ytRegex)
      return (
        <div className="embed-responsive embed-responsive-16by9">
          <iframe
            width="100%"
            height="100%"
            src={"https://youtube.com/embed/" + dataUrlValid[1]}
            allowFullScreen
            title={article.title}
          ></iframe>
        </div>
      )
    }
  }

  return (
    <AuthProvider>
      <AuthConsumer>
        {({ isAuth, user }) => (
          <Layout>
            <Seo title={article.title} />
            <section className="hero is-dark is-small">
              <div className="hero-body">
                <div className="container pb-6">
                  <div
                    className="field is-grouped is-grouped-multiline mt-6"
                    style={{ justifyContent: "center" }}
                  >
                    <CategoryTag type={article.category.name} />
                  </div>
                  <div className="columns is-centered">
                    <div className="column is-7 has-text-centered">
                      <h1 className="is-size-3 has-text-weight-bold has-text-white title-font">
                        {article.title}
                      </h1>
                      <p className="has-text-white">{article.description}</p>
                      <a
                        href={article.url}
                        target="_blank"
                        rel="noreferrer"
                        className="is-inline-block is-size-6 has-text-white mt-4 has-opacity-65"
                      >
                        <svg
                          width="50"
                          height="50"
                          viewBox="0 0 50 50"
                          fill="none"
                          className="is-16 top-2px"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M37 4.00391C34.6953 4.00378 32.3898 4.88222 30.6348 6.63672L25.6348 11.6367C23.1428 14.1287 22.4306 17.7273 23.4786 20.8633L27.0703 17.2715C27.2203 16.2445 27.6739 15.2538 28.4629 14.4648L33.4629 9.46484C34.4379 8.49084 35.72 8.00195 37 8.00195C38.28 8.00195 39.5612 8.48984 40.5352 9.46484C42.4842 11.4148 42.4842 14.5862 40.5352 16.5352L35.5352 21.5352C34.7462 22.3242 33.7566 22.7767 32.7286 22.9277L29.1348 26.5215C30.0628 26.8315 31.0291 26.9961 31.9981 26.9961C34.3031 26.9961 36.6083 26.1183 38.3633 24.3633L43.3633 19.3633C46.8733 15.8543 46.8723 10.1457 43.3633 6.63672C41.6083 4.88272 39.3048 4.00403 37 4.00391ZM30.961 16.9805C30.4414 16.996 29.9482 17.2131 29.586 17.5859L17.586 29.5859C17.394 29.7702 17.2408 29.991 17.1352 30.2352C17.0296 30.4795 16.9739 30.7423 16.9712 31.0084C16.9684 31.2745 17.0189 31.5385 17.1194 31.7848C17.22 32.0312 17.3687 32.255 17.5569 32.4431C17.7451 32.6313 17.9689 32.78 18.2152 32.8806C18.4616 32.9812 18.7255 33.0316 18.9916 33.0289C19.2577 33.0262 19.5206 32.9704 19.7648 32.8648C20.0091 32.7593 20.2298 32.606 20.4141 32.4141L32.4141 20.4141C32.7031 20.1331 32.9005 19.7715 32.9804 19.3764C33.0603 18.9814 33.019 18.5714 32.862 18.2002C32.7049 17.8291 32.4393 17.514 32.1001 17.2963C31.7609 17.0787 31.3638 16.9686 30.961 16.9805V16.9805ZM18.4493 23.0234C15.9972 22.8987 13.5065 23.7658 11.6368 25.6348L6.63675 30.6348C3.12775 34.1438 3.12775 39.8543 6.63675 43.3633C8.39175 45.1173 10.696 45.9941 13 45.9941C15.304 45.9941 17.6083 45.1163 19.3633 43.3613L24.3633 38.3613C26.8553 35.8693 27.5695 32.2708 26.5215 29.1348L22.9278 32.7285C22.7778 33.7555 22.3242 34.7462 21.5352 35.5352L16.5352 40.5352C14.5862 42.4852 11.4139 42.4852 9.46488 40.5352C7.51588 38.5852 7.51588 35.4138 9.46488 33.4648L14.4649 28.4648C15.2539 27.6758 16.2445 27.2213 17.2715 27.0703L20.8633 23.4785C20.0793 23.2165 19.2666 23.065 18.4493 23.0234Z"
                            fill="#fff"
                          />
                        </svg>
                        {article.category.name === "Videos" ? (
                          <span className="ml-2">Go to video</span>
                        ) : (
                          ""
                        )}
                        {article.category.name !== "Videos" ? (
                          <span className="ml-2">Link</span>
                        ) : (
                          ""
                        )}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="section">
              <div className="container">
                <div className="columns is-centered">
                  <div className="column is-8">
                    <div className="content">
                      {article.category.name === "Videos" ? (
                        formatVideo()
                      ) : (
                        <figure className="image">
                          <img src={article.media} alt={article.media} />
                        </figure>
                      )}
                      <ReactMarkdown source={article.content} />
                    </div>
                    <hr />
                    <div
                      className="is-flex"
                      style={{
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Upvote
                        isAuth={isAuth}
                        loggedUser={user}
                        articleId={article.strapiId}
                      />
                      <p className="has-opacity-65 my-0">
                        Added on{" "}
                        <Moment format="MMM Do YYYY">
                          {article.published_at}
                        </Moment>
                      </p>
                    </div>
                    <hr />
                    <div className="content">
                      <p>Help this resource gain popularity</p>
                      <ShareButtons
                        title={title}
                        url={url}
                        twitterHandle={twitterHandle}
                        tags={tags}
                        summary={summary}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </Layout>
        )}
      </AuthConsumer>
    </AuthProvider>
  )
}

export default Article
